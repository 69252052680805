import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AxiosError } from 'axios';

import {
  Chart,
  Series,
  ArgumentAxis,
  ValueAxis,
  CommonSeriesSettings,
  Legend,
  Label,
  Tooltip,
  Grid,
} from 'devextreme-react/chart';
import { Properties } from 'devextreme/ui/tree_list';

import { makeStyles } from '@mui/styles';
import Dialog from '@mui/material/Dialog';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';

import { getInjectionReportGraphs } from '../../api/getInjectionReportGraphs';
import { DRUGS } from './DrugsMap';
import { getInjectionGraphs } from '../../api/getInjectionGraphs';
import { routes } from '../../routes';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import {
  getFormattedDate2,
  getFormattedDateAndTimeWithoutConverting,
} from '../../helpers/getFormattedDate';
import { INJECTIONS_OPTIONS } from './InjectionOptions';
import Table from '@mui/material/Table';
import { TInjection } from '../../api/getInjections';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import {useTranslation} from "react-i18next";

const useStyles = makeStyles({
  container: {
    padding: '20px',
  },
  toTheLeft: {
    float: 'right',
  },
  error: {
    color: 'red',
  },
  chartContainer: {
    width: '100%',
    overflow: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  chartWrapper: {
    width: '100%',
    minWidth: '600px',
  },
});

const convertArrayToObject = (arr) =>
  arr.reduce((result, item) => {
    const key = Object.keys(item)[0];
    result[key] = item[key];
    return result;
  }, {});

const formatDate = (date) => {
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = String(date.getFullYear()).substr(2, 2);
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  return `${hours}:${minutes} <b>${day}.${month}.${year}</b>`;
};

const format = ({ value }) => {
  const date = new Date(value);
  return formatDate(date);
};

function CustomizeTooltip(t) {
  //const { t } = useTranslation(); - Рудов сказал скрыть, иначе не работает на графике модалочка
  return function(pointInfo) {
    const point = pointInfo.point.data;
    const date = formatDate(new Date(point.dt));
    const otherKeys = Object.keys(point).filter((key) => key !== 'dt');
    const otherData = otherKeys
        .map((key) => `${DRUGS[key] || t('sum_medication')} - ${point[key]}`)
        .join('<br>');

    return {
      text: `${date}<br>${otherData}`,
    };
  }
}

export function InjectionGraphModal(props) {
  const { t } = useTranslation();
  const { onClose, injectionId, open, dates } = props;
  const [data, setData] = useState<Properties<string, number>['dataSource'] | AxiosError | null>();
  const [inject, setInject] = useState<TInjection[] | AxiosError | null>();
  const [countProcess, setCountProcess] = useState(0);
  const [control, setControl] = useState('');
  const [pkt, setPkt] = useState('');
  const [anabolicIndex, setAnabolicIndex] = useState(0);
  const [androgenicIndex, setAndrogenicIndex] = useState(0);
  const classes = useStyles();
  const history = useHistory();

  const theme = useTheme();
  const smSize = useMediaQuery(theme.breakpoints.up('sm'));

  useEffect(() => {
    setData(undefined);
    if (open && (injectionId || dates)) {
      let tmpData;
      if (injectionId) {
        tmpData = getInjectionGraphs(injectionId);
      } else if (dates) {
        tmpData = getInjectionReportGraphs(dates);
      }

      if (!tmpData) {
        return;
      }

      tmpData
        .then((res) => {
          if (res.point && res.point.length !== 0) {
            let graphData = res.point.map((val) => ({
              dt: val.dt,
              ...convertArrayToObject(val.pointValues.map((item) => ({ [item.drug]: item.CCT }))),
            }));
            if (Object.keys(graphData[0]).length === 3) {
              graphData = graphData.map((point) => {
                const drugName = Object.keys(point).filter(
                  (key) => !['SUMM', 'dt'].includes(key)
                )[0];
                return { [drugName]: point[drugName], dt: point.dt };
              });
            }
            setData(graphData);
          } else {
            setData(null);
          }
          //сколько уколов ещё в расчёте
          setCountProcess(res.countProcess);
          setControl(res.control);
          setPkt(res.pkt);
          setAnabolicIndex(res.anabolicIndex);
          setAndrogenicIndex(res.androgenicIndex);
          setInject(res.Injections);
        })
        .catch((error: AxiosError) => {
          setData(error);
        });
    }
  }, [injectionId, dates, open]);

  const handleClose = () => {
    onClose();
  };
  //const { t } = useTranslation();
  if (inject instanceof Error) {
    if (inject && inject?.response?.status === 401) {
      history.push(routes.login);
      return null;
    }
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t('err_build')}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <h3 className={classes.error}>
              {inject.response ? inject.response.data.message : inject.message}
            </h3>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
  if (data instanceof Error) {
    if (data && data?.response?.status === 401) {
      history.push(routes.login);
      return null;
    }
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t('err_build')}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <h3 className={classes.error}>
              {data.response ? data.response.data.message : data.message}
            </h3>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth="xl">
      <DialogContent dividers>
        <div className={classes.container}>
          {t('use_report')}
          {countProcess !== 0 ? (
            <h3 className={classes.error}>
              {t('chart_build_after')} {countProcess} {t('uses_')}
            </h3>
          ) : null}
          {data === undefined ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '90vh',
              }}
            >
              <CircularProgress />
            </Box>
          ) : data === null ? (
            <h3>{t('no_data_for_chart')}</h3>
          ) : (
            <div className={classes.chartContainer}>
              <div className={classes.chartWrapper}>
                <Chart palette="Material" dataSource={data}>
                  <CommonSeriesSettings type="line" />
                  <ValueAxis title={t('concentration_nmol')} />
                  {data &&
                    Object.keys(data[0])
                      .filter((key) => key !== 'dt')
                      .map((key) => (
                        <Series
                          key={key}
                          name={DRUGS[key] || t('sum_medication')}
                          valueField={key}
                          argumentField="dt"
                        />
                      ))}
                  <ArgumentAxis valueMarginsEnabled={false} discreteAxisDivisionMode="dt">
                    <Grid visible />
                    <Label visible customizeText={format} />
                  </ArgumentAxis>
                  <Tooltip enabled zIndex={10000} customizeTooltip={CustomizeTooltip(t)} />
                  <Legend
                    verticalAlignment="top"
                    horizontalAlignment="center"
                    itemTextPosition="bottom"
                  />
                </Chart>
              </div>
            </div>
          )}
        </div>

        <div className={classes.container}>
          {smSize ? (
            <Table size="small">
              <TableBody>
                <TableRow>
                  <TableCell>
                    {t('anabolic_index')}: <b>{anabolicIndex}</b>{' '}
                  </TableCell>
                  <TableCell>
                    {t('clean_day')}: <b>{getFormattedDate2(control)}</b>{' '}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    {t('andro_index')}: <b>{androgenicIndex} </b>{' '}
                  </TableCell>
                  <TableCell>
                    {t('date_pkt')}: <b>{getFormattedDate2(pkt)} </b>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          ) : (
            <>
              <Card sx={{ mt: 2 }}>
                <CardContent>
                  <div>{t('anabolic_index') + `: ${anabolicIndex}`}</div>
                  <div>{t('clean_day') + `: ${getFormattedDate2(control)}`}</div>
                </CardContent>
              </Card>
              <Card sx={{ mt: 2 }}>
                <CardContent>
                  <div>{t('andro_index') + `: ${androgenicIndex}`}</div>
                  <div>{t('date_pkt') + `: ${getFormattedDate2(pkt)}`}</div>
                </CardContent>
              </Card>
            </>
          )}
        </div>

        <div className={classes.container}>
          <b>{t('used_farm')}</b>
          <br />
          {smSize ? (
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>{t('date')}</TableCell>
                  <TableCell>{t('adm_type')}</TableCell>
                  <TableCell>{t('medication1')} - {t('dose_vol')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {inject &&
                  inject.map(({ injection, injection_dose }) => (
                    <TableRow key={injection.ID} hover>
                      <TableCell>
                        {getFormattedDateAndTimeWithoutConverting(injection.dt)}
                      </TableCell>
                      <TableCell>{INJECTIONS_OPTIONS[injection.what]}</TableCell>
                      <TableCell>
                        {injection_dose.map((dose) => (
                          <div key={dose.id}>
                            {DRUGS[dose.drug]} - {dose.dose} / {dose.volume}
                          </div>
                        ))}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          ) : (
            inject &&
            inject.map(({ injection, injection_dose }) => (
              <Card key={injection.ID} sx={{ mt: 2 }}>
                <CardContent>
                  <div>{t('date') + `: ${getFormattedDateAndTimeWithoutConverting(injection.dt)}`}</div>
                  <div>{t('adm_type') + `: ${INJECTIONS_OPTIONS[injection.what]}`}</div>
                  <br />
                  <div>{t('medication1') + ' - ' + t('dose_vol')}:</div>
                  {injection_dose.map((dose) => (
                    <div key={dose.id}>
                      {DRUGS[dose.drug]} - {dose.dose} / {dose.volume}
                    </div>
                  ))}
                </CardContent>
              </Card>
            ))
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}
